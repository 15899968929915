import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientTab: "Home",
  clientDetail: {},
  itemTab: "details",
  communicationTab: "emails",
  actionPlanTab: "actionPlan",
  documentTab: "document",
  segmentTab: "unarchived",
  workFlowClientTab: "unarchived",
  workFlowDisputeTab: "unarchived",
  systemTab: "Bureau Address",
  itemDropDownsTab: "instructions",
  contractsTab: "Credit Repair Contracts",
  batchPrints: "batchPrints",
  taskTab: "All Tasks",
  templateTab: "Static Templates",
  notesTemplateTab: "Templates",
  managementTab: "Users",
  managementTab: "Users",
};

const reducers = {
  setClientTab(state, action) {
    const { payload } = action;
    state.clientTab = payload;
  },
  setClientDetail(state, action) {
    const { payload } = action;
    state.clientDetail = payload;
  },
  setItemTab(state, action) {
    const { payload } = action;
    state.itemTab = payload;
  },
  setCommunicationTab(state, action) {
    const { payload } = action;
    state.communicationTab = payload;
  },
  setActionPlanTab(state, action) {
    const { payload } = action;
    state.actionPlanTab = payload;
  },
  setDocumentTab(state, action) {
    const { payload } = action;
    state.documentTab = payload;
  },
  setSegmentTab(state, action) {
    const { payload } = action;
    state.segmentTab = payload;
  },
  setWorkFlowClientTab(state, action) {
    const { payload } = action;
    state.workFlowClientTab = payload;
  },
  setWorkFlowDisputeTab(state, action) {
    const { payload } = action;
    state.workFlowDisputeTab = payload;
  },
  setSystemTab(state, action) {
    const { payload } = action;
    state.systemTab = payload;
  },
  setItemDropDownsTab(state, action) {
    const { payload } = action;
    state.itemDropDownsTab = payload;
  },
  setContractsTab(state, action) {
    const { payload } = action;
    state.contractsTab = payload;
  },
  setBatchPrintsTab(state, action) {
    const { payload } = action;
    state.batchPrints = payload;
  },
  setTaskTab(state, action) {
    const { payload } = action;
    state.taskTab = payload;
  },
  setTemplateTab(state, action) {
    const { payload } = action;
    state.templateTab = payload;
  },
  setManagementTab(state, action) {
    const { payload } = action;
    state.managementTab = payload;
  },
  setNotesTemplateTab(state, action) {
    const { payload } = action;
    state.notesTemplateTab = payload;
  },
};

const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers,
});

export const {
  setContractsTab,
  setItemDropDownsTab,
  setDocumentTab,
  setActionPlanTab,
  setSystemTab,
  setWorkFlowDisputeTab,
  setWorkFlowClientTab,
  setSegmentTab,
  setCommunicationTab,
  setItemTab,
  setClientDetail,
  setClientTab,
  setBatchPrintsTab,
  setTaskTab,
  setTemplateTab,
  setManagementTab,
  setNotesTemplateTab
} = tabsSlice.actions;
export default tabsSlice.reducer;
