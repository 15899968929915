import { createSlice } from "@reduxjs/toolkit";
import { initialStatus } from "../components/common";

const initialState = {
  segmentsActiveList: {},
  segmentsActiveStatus: initialStatus,
  segmentsArchivedList: {},
  segmentsArchivedStatus: initialStatus,
  workflowUsage: {},
  workflowUsageStatus: initialStatus,
  segmentData: {},
  segmentStatus: initialStatus,
  toastStatus: { message: "", color: "", status: "LOADING" },
  backViewId: null,
  total: { active: 0, archieved: 0 },
};

const reducers = {
  setSegmentsActiveList(state, action) {
    state.segmentsActiveList = action.payload;
  },
  setSegmentsActiveStatus(state, action) {
    state.segmentsActiveStatus = action.payload;
  },
  setSegmentsArchivedList(state, action) {
    state.segmentsArchivedList = action.payload;
  },
  setSegmentsArchivedStatus(state, action) {
    state.segmentsArchivedStatus = action.payload;
  },
  setWorkflowUsage(state, action) {
    state.workflowUsage = action.payload;
  },
  setWorkflowUsageStatus(state, action) {
    state.workflowUsageStatus = action.payload;
  },
  setSegmentData(state, action) {
    state.segmentData = action.payload;
  },
  setSegmentStatus(state, action) {
    state.segmentStatus = action.payload;
  },
  setToastStatus(state, action) {
    state.toastStatus = action.payload;
  },
  setBackViewId(state, action) {
    state.backViewId = action.payload;
  },
  setTotal(state, action) {
    state.total = { ...state.total, ...action.payload };
  },
};

const segmentSlice = createSlice({
  name: "settings",
  initialState,
  reducers,
});

export const {
  setSegmentsActiveList,
  setSegmentsActiveStatus,
  setSegmentsArchivedList,
  setSegmentsArchivedStatus,
  setSegmentData,
  setSegmentStatus,
  setWorkflowUsage,
  setWorkflowUsageStatus,
  setToastStatus,
  setBackViewId,
  setTotal,
} = segmentSlice.actions;
export default segmentSlice.reducer;
