import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 1,
  rowsPerPage: 10,
  search: "",
};

const reducers = {
  setPage(state, action) {
    state.page = action.payload;
  },
  setRowsPerPage(state, action) {
    state.rowsPerPage = action.payload;
  },
  setSearch(state, action) {
    state.search = action.payload;
  },
  resetItems(state, action) {
    state.page = initialState.page;
    state.rowsPerPage = initialState.rowsPerPage;
    state.search = initialState.search;
  },
};

const itemSlice = createSlice({
  name: "items",
  initialState,
  reducers,
});

export const {
  setPage,
  setRowsPerPage,
  setSearch,
  resetItems,
} = itemSlice.actions;
export default itemSlice.reducer;
