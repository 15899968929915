import { configureStore } from "@reduxjs/toolkit";
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import { enableDevTools } from "../config";
import { rootReducer } from "./root-reducer";

const persistConfig = {
  key: "root",
  storage: storageSession,
  blacklist: [
    "clients",
    "advanceFilter",
    "items",
    "settings",
    "workflows",
    "segments",
    "system",
    "task",
    "template",
    "billingSetup",
    "managements",
    "activity",
  ],
  stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: enableDevTools,
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export const persistor = persistStore(store);
