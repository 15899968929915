import PropTypes from "prop-types";
import dashboardLogo from "../../../../public/assets/logo/tcpDashboardLogo.png";
import logo from "../../../../public/assets/logo/tcpLoginLogoPNG.png";
import darkLogo from "../../../../public/assets/logo/darkThemeLogo.png";
import { useSettings } from "../../../hooks/use-settings";

export const Logo = () => {
  const settings = useSettings();
  const logoSource = settings.paletteMode === "dark" ? darkLogo : logo;
  return <img className="login_logo" src={logoSource.src} alt="dashboard img" />;
};

export const DashboardLogo = ({ width }) => {
  return (
    <img
      src={dashboardLogo.src}
      alt=""
      width={width}
      style={{
        maxWidth: "100%",
        height: "auto",
        padding: 0,
        margin: 0,
      }}
    />
  );
};

DashboardLogo.propTypes = {
  width: PropTypes.string,
};
