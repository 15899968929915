import { CacheProvider } from "@emotion/react";
import Head from "next/head";
import "react-quill/dist/quill.snow.css";
import { Provider as ReduxProvider } from "react-redux";
import { SettingsButton } from "../components/settings/settings-button";
import { SettingsDrawer } from "../components/settings/settings-drawer";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Rtl } from "../components/rtl.js";
import { SplashScreen } from "../components/splash-screen.js";
import "simplebar-react/dist/simplebar.min.css";
import { Toaster } from "../components/utility/toaster.js";
import { AuthConsumer } from "../context/auth/auth-consumer.js";
import { AuthProvider } from "../context/auth/auth-provider.js";
import { SettingsConsumer } from "../context/settings/settings-consumer";
import { SettingsProvider } from "../context/settings/settings-provider";
import { useNprogress } from "../hooks/use-nprogress.js";
import { persistor, store } from "../store";
import { createTheme } from "../theme";
import { createEmotionCache } from "../utils/create-emotion-cache";
import "../styles/custom.css";
import PropTypes from "prop-types";
import { PersistGate } from "redux-persist/integration/react";

const clientSideEmotionCache = createEmotionCache();

const CustomApp = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  useNprogress();

  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>The Credit Pros</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <AuthConsumer>
              {(auth) => (
                <SettingsProvider>
                  <SettingsConsumer>
                    {(settings) => {
                      const theme = createTheme({
                        colorPreset: settings.colorPreset,
                        contrast: settings.contrast,
                        direction: settings.direction,
                        paletteMode: settings.paletteMode,
                        responsiveFontSizes: settings.responsiveFontSizes,
                      });

                      // Prevent guards from redirecting
                      const showSlashScreen = !auth.isInitialized;
                      return (
                        <ThemeProvider theme={theme}>
                          <Head>
                            <meta
                              name="color-scheme"
                              content={settings.paletteMode}
                            />
                            <meta
                              name="theme-color"
                              content={theme.palette.neutral[900]}
                            />
                          </Head>
                          <Rtl direction={settings.direction}>
                            <CssBaseline />
                            {showSlashScreen ? (
                              <SplashScreen />
                            ) : (
                              <>
                                {getLayout(<Component {...pageProps} />)}
                                <SettingsButton
                                  onClick={settings.handleDrawerOpen}
                                />
                                <SettingsDrawer
                                  canReset={settings.isCustom}
                                  onClose={settings.handleDrawerClose}
                                  onReset={settings.handleReset}
                                  onUpdate={settings.handleUpdate}
                                  open={settings.openDrawer}
                                  values={{
                                    colorPreset: settings.colorPreset,
                                    contrast: settings.contrast,
                                    direction: settings.direction,
                                    paletteMode: settings.paletteMode,
                                    responsiveFontSizes:
                                      settings.responsiveFontSizes,
                                    stretch: settings.stretch,
                                    layout: settings.layout,
                                    navColor: settings.navColor,
                                  }}
                                />
                              </>
                            )}
                            <Toaster />
                          </Rtl>
                        </ThemeProvider>
                      );
                    }}
                  </SettingsConsumer>
                </SettingsProvider>
              )}
            </AuthConsumer>
          </AuthProvider>
        </PersistGate>
      </ReduxProvider>
    </CacheProvider>
  );
};

CustomApp.propTypes = {
  Component: PropTypes.any,
  emotionCache: PropTypes.any,
  pageProps: PropTypes.any.isRequired,
};

export default CustomApp;
