import axios, { HttpStatusCode } from "axios";
import { authApiService } from "../../services/auth";

export const STORAGE_KEY = "accessToken";
export const COMPANY_ID = "companyId";
export const USER_STORAGE_KEY = "currentUser";

export const updateToken = () => {
  let token = "";
  if (typeof window !== "undefined") {
    token = localStorage.getItem(STORAGE_KEY);
  }
  return token;
};

const API_URL = process.env.API_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = updateToken();

const onResponseError = async (error) => {
  if (error.response) {
    const originalConfig = error.config;
    if (error.response.status === 401 && localStorage.getItem(STORAGE_KEY)) {
      try {
        originalConfig._retry = true;
        if (error.response.config.url !== `${API_URL}/refresh`) {
          const resp = await axiosApi
            .post(
              `${API_URL}/refresh`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(STORAGE_KEY)}`,
                },
              }
            )
            .then((resp) => {
              localStorage.setItem(
                STORAGE_KEY,
                resp.data.results.authorisation.token
              );
              originalConfig.headers["Authorization"] =
                "Bearer " + localStorage.getItem(STORAGE_KEY);
              return axiosApi(originalConfig);
            })
            .catch((err) => {
              if (
                err.response.status == HttpStatusCode.Unauthorized ||
                err?.response?.data?.message === "Could not refresh token."
              ) {
                authApiService.logout();
              }
            });
          return resp;
        }
        return Promise.reject(error);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

axiosApi.interceptors.response.use((response) => response, onResponseError);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function pat(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
