import { createSlice } from "@reduxjs/toolkit";
import { initialStatus } from "../components/common";
const initialState = {
    toastStatus: { errors: "", message: "", color: "", status: "LOADING" },
    billingCategoryList: {},
    billingCategoryListStatus: initialStatus,
    billingStatusList: {},
    billingStatusListStatus: initialStatus,
    allBillingCategory: null,
    clonePortalBillingStatus: null,
};

const billingSetupSlice = createSlice({
    name: "billingSetup",
    initialState,
    reducers: {
        setBillingCategoryList: (state, action) => {
            const { payload } = action;
            state.billingCategoryList = payload;
        },
        setBillingCategoryListStatus: (state, action) => {
            const { payload } = action;
            state.billingCategoryListStatus = payload;
        },
        setBillingStatusList: (state, action) => {
            const { payload } = action;
            state.billingStatusList = payload;
        },
        setBillingStatusListStatus: (state, action) => {
            const { payload } = action;
            state.billingStatusListStatus = payload;
        },
        setAllBillingCategory: (state, action) => {
            const { payload } = action;
            state.allBillingCategory = payload;
        },
        setClonePortalBillingStatus: (state, action) => {
            const { payload } = action;
            state.clonePortalBillingStatus = payload;
        },
        setToastStatus: (state, action) => {
            const { payload } = action;
            state.toastStatus = payload;
        },
    },
});

export const {
    setToastStatus,
    setBillingCategoryList,
    setBillingCategoryListStatus,
    setBillingStatusList,
    setBillingStatusListStatus,
    setAllBillingCategory,
    setClonePortalBillingStatus,
} = billingSetupSlice.actions;
export default billingSetupSlice.reducer;
