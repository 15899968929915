import { createContext } from "react";

export const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export const AuthContext = createContext({
  ...initialState,
  issuer: "JWT",
  signIn: () => Promise.resolve(),
  signInWithOtp: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});
