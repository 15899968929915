import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useReducer } from "react";

import { requestStatus } from "../../data/status";
import {
  STORAGE_KEY,
  COMPANY_ID,
  USER_STORAGE_KEY,
} from "../../helpers/api/api_helper";
import { authApiService } from "../../services/auth";
import { Issuer } from "../../utils/auth";
import { AuthContext, initialState } from "./auth-context";
import { usePathname } from "next/navigation";

const ActionType = {
  INITIALIZE: "INITIALIZE",
  SIGN_IN: "SIGN_IN",
  SIGN_UP: "SIGN_UP",
  SIGN_OUT: "SIGN_OUT",
  SIGN_IN_WITH_OTP: "SIGN_IN_WITH_OTP",
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  SIGN_IN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      user,
    };
  },
  SIGN_IN_WITH_OTP: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_UP: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const pathname = usePathname();

  const initialize = () => {
    try {
      const accessToken = window.localStorage.getItem(STORAGE_KEY);

      if (accessToken) {
        const user = JSON.parse(window.localStorage.getItem(USER_STORAGE_KEY));
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  useEffect(() => {
    initialize();
  }, [dispatch, pathname]);

  const signIn = useCallback(
    async (email, password) => {
      const response = await authApiService.login({ email, password });
      if (response.status === requestStatus.SUCCESS) {
        dispatch({
          type: ActionType.SIGN_IN,
          payload: { user: null },
        });
        return response;
      }
    },
    [dispatch]
  );

  const signInWithOtp = useCallback(
    async (email, otp) => {
      const response = await authApiService.verifyOtp({ email, otp });
      const user = {
        name: response.results.user.name,
        email: response.results.user.email,
        userId: response.results.user.id,
        uniqueAccount: response.results.unique_account.id,
        role: {
          id: response.results.user.role_id,
          name: response.results.role_permission.role_name,
          permissions: response.results.role_permission.role_permission,
        },
      };
      const companyId = response.results.company_id;
      const accessToken = response.results.authorisation.token;

      localStorage.setItem(STORAGE_KEY, accessToken);
      localStorage.setItem(COMPANY_ID, companyId);
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));

      dispatch({
        type: ActionType.SIGN_IN_WITH_OTP,
        payload: { user },
      });
      return response;
    },
    [dispatch]
  );

  const signUp = useCallback(
    async (email, name, password) => {
      const { accessToken } = await authApiService.signUp({
        email,
        name,
        password,
      });
      const user = await authApiService.me({ accessToken });

      localStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const signOut = useCallback(async () => {
    localStorage.clear();
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  const value = useMemo(
    () => ({
      ...state,
      issuer: Issuer.JWT,
      signIn,
      signInWithOtp,
      signUp,
      signOut,
    }),
    [state, Issuer.JWT, signIn, signInWithOtp, signUp, signOut]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
