import { createSlice } from "@reduxjs/toolkit";
import { initialStatus } from "../components/common";
const initialState = {
  toastStatus: { message: "", color: "", status: "LOADING" },
  taskList: {},
  taskListStatus: initialStatus,
  taskTagList: [],
  taskTagStatus: initialStatus,
  usersList: [],
  associatedClients: null,
  viewByTaskTagsList: [],
  myTaskList: {},
  myTaskListStatus: initialStatus,
  manageTagsList: {},
  manageTagsListStatus: initialStatus,
  dropBoxData: {},
  dropBoxDataStatus: initialStatus,
  allTaskTags: null,
  allTaskTagsStatus: initialStatus,
  taskManageCategoryList: {},
  taskManageCategoryListStatus: initialStatus,
  sendDropboxList: null,
  taskActivities: {},
  taskActivitiesStatus: initialStatus,
  taskDetails: [],
  taskDetailsStatus: initialStatus,
  allTags: null,
  allResultTags: null,
  allCategory: null,
  wordsPhrases: [],
  wordsPhrasesStatus: initialStatus,
};

const reducers = {
  setTaskList(state, action) {
    const { payload } = action;
    state.taskList = payload;
  },
  setTaskStatus(state, action) {
    const { payload } = action;
    state.taskListStatus = payload;
  },
  setTaskTagList(state, action) {
    const { payload } = action;
    state.taskTagList = payload;
  },
  setTaskTagStatus(state, action) {
    const { payload } = action;
    state.taskTagStatus = payload;
  },
  setUsersList(state, action) {
    const { payload } = action;
    state.usersList = payload.sort((a, b) =>
      a.first_name.localeCompare(b.first_name)
    );
  },
  setAssociatedClients(state, action) {
    const { payload } = action;
    state.associatedClients = payload;
  },
  setViewByTaskTagsList(state, action) {
    const { payload } = action;
    state.viewByTaskTagsList = payload;
  },
  setToastStatus(state, action) {
    const { payload } = action;
    state.toastStatus = payload;
  },
  setMyTaskList(state, action) {
    const { payload } = action;
    state.myTaskList = payload;
  },
  setMyTaskListStatus(state, action) {
    const { payload } = action;
    state.myTaskListStatus = payload;
  },
  setManageTagsList(state, action) {
    const { payload } = action;
    state.manageTagsList = payload;
  },
  setManageTagsListStatus(state, action) {
    const { payload } = action;
    state.manageTagsListStatus = payload;
  },
  setDropBoxData(state, action) {
    const { payload } = action;
    state.dropBoxData = payload;
  },
  setDropBoxDataStatus(state, action) {
    const { payload } = action;
    state.dropBoxDataStatus = payload;
  },
  setAllTaskTags(state, action) {
    const { payload } = action;
    state.allTaskTags = payload;
  },
  setAllTaskTagsStatus(state, action) {
    const { payload } = action;
    state.allTaskTagsStatus = payload;
  },
  setTaskManageCategoryList(state, action) {
    const { payload } = action;
    state.taskManageCategoryList = payload;
  },
  setTaskManageCategoryStatus(state, action) {
    const { payload } = action;
    state.taskManageCategoryListStatus = payload;
  },
  setSendDropboxList(state, action) {
    const { payload } = action;
    state.sendDropboxList = payload;
  },
  setTaskActivities(state, action) {
    const { payload } = action;
    state.taskActivities = payload;
  },
  setTaskActivitiesStatus(state, action) {
    const { payload } = action;
    state.taskActivitiesStatus = payload;
  },
  setTaskDetails(state, action) {
    const { payload } = action;
    state.taskDetails = payload;
  },
  setTaskDetailsStatus(state, action) {
    const { payload } = action;
    state.taskDetailsStatus = payload;
  },
  setAllTags(state, action) {
    const { payload } = action;
    state.allTags = payload;
  },
  setAllResultTags(state, action) {
    const { payload } = action;
    state.allResultTags = payload;
  },
  setAllCategory(state, action) {
    const { payload } = action;
    state.allCategory = payload;
  },
  setWordsPhrases(state, action) {
    const { payload } = action;
    state.wordsPhrases = payload;
  },
  setWordsPhrasesStatus(state, action) {
    const { payload } = action;
    state.wordsPhrasesStatus = payload;
  },
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers,
});

export const {
  setToastStatus,
  setTaskList,
  setTaskTagList,
  setTaskTagStatus,
  setUsersList,
  setAssociatedClients,
  setViewByTaskTagsList,
  setTaskStatus,
  setMyTaskList,
  setMyTaskListStatus,
  setManageTagsList,
  setManageTagsListStatus,
  setDropBoxData,
  setDropBoxDataStatus,
  setAllTaskTags,
  setAllTaskTagsStatus,
  setTaskManageCategoryList,
  setTaskManageCategoryStatus,
  setSendDropboxList,
  setTaskActivities,
  setTaskActivitiesStatus,
  setTaskDetails,
  setTaskDetailsStatus,
  setAllTags,
  setAllCategory,
  setAllResultTags,
  setWordsPhrases,
  setWordsPhrasesStatus,
} = taskSlice.actions;
export default taskSlice.reducer;
