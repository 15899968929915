import Box from "@mui/material/Box";
import { DashboardLogo } from "./utility/logo/logo";

export const SplashScreen = () => (
  <Box
    sx={{
      alignItems: "center",
      backgroundColor: "background.paper",
      display: "flex",
      height: "100vh",
      justifyContent: "center",
      width: "100vw",
      overflow: "hidden",
    }}>
    <DashboardLogo width="30%" />
  </Box>
);
