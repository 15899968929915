import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const OptionsNavColorContrast = (props) => {
  const { onChange, value, lable } = props;

  const options = () => {
    if (lable === "Nav Color") {
      return [
        {
          label: "Blend-in",
          value: "blend-in",
        },
        {
          label: "Discrete",
          value: "discrete",
        },
        {
          label: "Evident",
          value: "evident",
        },
      ];
    }
    if (lable === "Contrast") {
      return [
        {
          label: "Normal",
          value: "normal",
        },
        {
          label: "High",
          value: "high",
        },
      ];
    }
  };
  return (
    <Stack spacing={1}>
      <Typography color="text.secondary" variant="overline">
        {lable}
      </Typography>
      <Stack alignItems="center" direction="row" flexWrap="wrap" gap={2}>
        {options().map((option) => (
          <Chip
            key={option.label}
            label={option.label}
            onClick={() => onChange?.(option.value)}
            sx={{
              borderColor: "transparent",
              borderRadius: 1.5,
              borderStyle: "solid",
              borderWidth: 2,
              ...(option.value === value && {
                borderColor: "primary.main",
              }),
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

OptionsNavColorContrast.propTypes = {
  lable: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.oneOf(
    ["blend-in", "discrete", "evident", "normal", "high"]
  ),
};
