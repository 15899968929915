import { combineReducers } from "@reduxjs/toolkit";
import clientReducer from "../slices/client";
import filterReducer from "../slices/advanceFilter";
import itemReducer from "../slices/item";
import tabsReducer from "../slices/tabs";
import settingReducer from "../slices/settings";
import workFlowReducer from "../slices/workflows";
import segmentReducer from "../slices/segment";
import systemReducer from "../slices/system";
import taskReducer from "../slices/task";
import templateReducer from "../slices/template";
import billingSetupReducer from "../slices/billingSetup";
import managementReducer from "../slices/managements";
import ActivityReducer from "../slices/activity";

export const rootReducer = combineReducers({
  clients: clientReducer,
  advanceFilter: filterReducer,
  items: itemReducer,
  settings: settingReducer,
  workflows: workFlowReducer,
  segments: segmentReducer,
  system: systemReducer,
  task: taskReducer,
  template: templateReducer,
  tabs: tabsReducer,
  billingSetup: billingSetupReducer,
  managements: managementReducer,
  activity: ActivityReducer,
});
