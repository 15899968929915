import { createSlice } from "@reduxjs/toolkit";
import { initialQuery } from "../components/common";

const initialState = {
  openAdvance: true,
  filters: "myfilter",
  query: initialQuery,
  selectedFilter: { id: "", name: "" },
  page: 1,
  search: "",
  sortDirection: "desc",
  sortby: "id",
  rowsPerPage: 10,
  onEnter: true,
  toastStatus: { message: "", color: "", status: "LOADING" },
};

const reducers = {
  setOpenAdvance(state, action) {
    state.openAdvance = action.payload;
  },
  setFilters(state, action) {
    state.filters = action.payload;
  },
  setSelectedFilter(state, action) {
    state.selectedFilter = action.payload;
  },
  setQuery(state, action) {
    state.query = action.payload;
  },
  setPage(state, action) {
    state.page = action.payload;
  },
  setRowsPerPage(state, action) {
    state.rowsPerPage = action.payload;
  },
  setSortby(state, action) {
    state.sortby = action.payload;
  },
  setSortDirection(state, action) {
    state.sortDirection = action.payload;
  },
  setSearch(state, action) {
    state.search = action.payload;
  },
  setOnEnter(state, action) {
    state.onEnter = action.payload;
  },
  setToastStatus(state, action) {
    state.toastStatus = action.payload;
  },
};

const filterSlice = createSlice({
  name: "advanceFilter",
  initialState,
  reducers,
});

export const {
  setOpenAdvance,
  setPage,
  setRowsPerPage,
  setSearch,
  setQuery,
  setSortby,
  setSortDirection,
  setFilters,
  setSelectedFilter,
  setOnEnter,
  setToastStatus,
} = filterSlice.actions;
export default filterSlice.reducer;
