import Router from "next/router";
import { STORAGE_KEY, get, post } from "../../helpers/api/api_helper";

export const authApiService = {
  login,
  logout,
  refresh,
  sendResetLink,
  forgotPassword,
  resetPassword,
  updateProfile,
  getProfile,
  sendOtp,
  verifyOtp,
};
function isLocalStorageAvailable(key) {
  if (window !== undefined) {
    return window.localStorage.getItem(key);
  } else {
    return null;
  }
}
async function login(request) {
  return await post("/login", request);
}

async function refresh(request) {
  let storageData = isLocalStorageAvailable(STORAGE_KEY);
  return await post("/refresh", request, {
    headers: { Authorization: `bearer ${storageData}` },
  });
}

async function sendOtp(request) {
  return await post("/request_otp", request).then((response) => {
    return response;
  });
}

async function verifyOtp(request) {
  return await post("/verify_otp", request).then((response) => {
    return response;
  });
}

async function sendResetLink(request) {
  return await post("/forgot_password", request);
}
async function forgotPassword(request) {
  return await post("/update_forget_password", request);
}

async function resetPassword(request) {
  return await post("/resetPassword", request);
}

async function updateProfile(request) {
  let storageData = isLocalStorageAvailable(STORAGE_KEY);
  return await post("/profile", request, {
    headers: { Authorization: `bearer ${storageData}` },
  });
}

async function getProfile() {
  let storageData = isLocalStorageAvailable(STORAGE_KEY);
  return await get("/profile", {
    headers: { Authorization: `bearer ${storageData}` },
  });
}

async function logout() {
  localStorage.clear();
  sessionStorage.clear();
  Router.push("/");
}
