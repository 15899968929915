import { createSlice } from "@reduxjs/toolkit";
const initialStatus = {
  status: "LOADING",
  message: "",
};

const initialState = {
  authkeys: {},
  authkeysStatus: initialStatus,
  authkey: {},
  authkeyStatus: initialStatus,
  importList: {},
  importListStatus: initialStatus,
  batchPrintSetting: {},
  batchPrintSettingStatus: initialStatus,
  notificationList: {},
  notificationListStatus: initialStatus,
  toastStatus: { message: "", color: "", status: "LOADING" },
};

const reducers = {
  setAuthKeys(state, action) {
    state.authkeys = action.payload;
  },
  setAuthKeysStatus(state, action) {
    state.authkeysStatus = action.payload;
  },
  setAuthKey(state, action) {
    state.authkey = action.payload;
  },
  setAuthKeyStatus(state, action) {
    state.authkeyStatus = action.payload;
  },
  setImportList(state, action) {
    state.importList = action.payload;
  },
  setImportListStatus(state, action) {
    state.importListStatus = action.payload;
  },
  setBatchPrintSetting(state, action) {
    state.batchPrintSetting = action.payload;
  },
  setBatchPrintSettingStatus(state, action) {
    state.batchPrintSettingStatus = action.payload;
  },
  setNotificationList(state, action) {
    state.notificationList = action.payload;
  },
  setNotificationListStatus(state, action) {
    state.notificationListStatus = action.payload;
  },
  setToastStatus(state, action) {
    state.toastStatus = action.payload;
  },
};

const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers,
});

export const {
  setAuthKeys,
  setAuthKeysStatus,
  setAuthKey,
  setAuthKeyStatus,
  setImportList,
  setImportListStatus,
  setBatchPrintSetting,
  setBatchPrintSettingStatus,
  setNotificationList,
  setNotificationListStatus,
  setToastStatus,
} = settingSlice.actions;
export default settingSlice.reducer;
