import { createSlice } from "@reduxjs/toolkit";

const initialStatus = {
  status: "LOADING",
  message: "",
};

const initialState = {
  allClients: {},
  clientsStatus: initialStatus,
  filtersList: [],
  getFilter: [],
  fieldValues: {
    billingStatus: [],
    monitoringSiteId: [],
    sequences: [],
    salesRep: [],
    states: [],
    tags: [],
  },
  clientDetails: null,
  clientStatus: initialStatus,
  letters: {},
  lettersStatus: initialStatus,
  clientNote: {},
  clientNoteStatus: initialStatus,
  clientActivity: {},
  clientActivityStatus: initialStatus,
  clientTask: {},
  clientTaskStatus: initialStatus,
  taskSuggetionData: [],
  clientActionPlan: {},
  clientActionPlanStatus: initialStatus,
  clientReview: {},
  clientReviewStatus: initialStatus,
  clientDocument: {},
  clientDocumentStatus: initialStatus,
  clientAgreement: {},
  clientAgreementStatus: initialStatus,
  items: {},
  itemsStatus: initialStatus,
  item: {},
  itemStatus: initialStatus,
  itemDetails: null,
  itemDetailsStatus: initialStatus,
  emails: {},
  sms: {},
  emailsStatus: initialStatus,
  smsStatus: initialStatus,
  systemCustomerTemplate: null,
  templateSnippitsList: null,
  templatePreview: [],
  smsTemplateList: null,
  clientSegmentList: {},
  clientSegmentListStatus: initialStatus,
  noteCategories: null,
  noteTemplates: null,
  financeGoals: null,
};

const reducers = {
  setAllClient(state, action) {
    state.allClients = action.payload;
  },
  setAllClientStatus(state, action) {
    state.clientsStatus = action.payload;
  },
  setFiltersList(state, action) {
    state.filtersList = action.payload;
  },
  setFilter(state, action) {
    state.getFilter = action.payload;
  },
  setFieldValues(state, action) {
    const { results, type } = action.payload;
    if (type == "billing_status_list") {
      state.fieldValues.billingStatus = results.map((element) => {
        return { name: element.id, label: element.name };
      });
    }
    if (type == "monitoring_site_listing") {
      state.fieldValues.monitoringSiteId = results.map((element) => {
        return { name: element.id, label: element.name };
      });
    }
    if (type == "sequence_list") {
      state.fieldValues.sequences = results.map((element) => {
        return { name: element.id, label: element.name };
      });
    }
    if (type == "users") {
      state.fieldValues.salesRep = results.map((element) => {
        return {
          name: element.id,
          label: element.first_name + " " + element.last_name,
        };
      });
    }
    if (type == "states") {
      state.fieldValues.states = results.map((element) => {
        return { name: element.code, label: element.name, id: element.id };
      });
    }
    if (type == "tags") {
      state.fieldValues.tags = results.map((element) => {
        return { name: element.id, label: element.tag };
      });
    }
  },
  setClientDetails(state, action) {
    state.clientDetails = action.payload;
  },
  setClientStatus(state, action) {
    state.clientStatus = action.payload;
  },
  setLetters(state, action) {
    state.letters = action.payload;
  },
  setLettersStatus(state, action) {
    state.lettersStatus = action.payload;
  },
  setClientNote(state, action) {
    state.clientNote = action.payload;
  },
  setClientNoteStatus(state, action) {
    state.clientNoteStatus = action.payload;
  },
  setClientActivity(state, action) {
    state.clientActivity = action.payload;
  },
  setClientActivityStatus(state, action) {
    state.clientActivityStatus = action.payload;
  },
  setClientTask(state, action) {
    state.clientTask = action.payload;
  },
  setClientTaskStatus(state, action) {
    state.clientTaskStatus = action.payload;
  },
  setTaskSuggetion(state, action) {
    state.taskSuggetionData = action.payload;
  },
  setClientActionPlan(state, action) {
    state.clientActionPlan = action.payload;
  },
  setClientActionPlanStatus(state, action) {
    state.clientActionPlanStatus = action.payload;
  },
  setClientReview(state, action) {
    state.clientReview = action.payload;
  },
  setClientReviewStatus(state, action) {
    state.clientReviewStatus = action.payload;
  },
  setClientDocument(state, action) {
    state.clientDocument = action.payload;
  },
  setClientDocumentStatus(state, action) {
    state.clientDocumentStatus = action.payload;
  },
  setClientAgreement(state, action) {
    state.clientAgreement = action.payload;
  },
  setClientAgreementStatus(state, action) {
    state.clientAgreementStatus = action.payload;
  },
  setItems(state, action) {
    state.items = action.payload;
  },
  setItemsStatus(state, action) {
    state.itemsStatus = action.payload;
  },
  setItem(state, action) {
    state.item = action.payload;
  },
  setItemStatus(state, action) {
    state.itemStatus = action.payload;
  },
  setItemDetails(state, action) {
    state.itemDetails = action.payload;
  },
  setItemDetailsStatus(state, action) {
    state.itemDetailsStatus = action.payload;
  },
  setEmails(state, action) {
    state.emails = action.payload;
  },
  setEmailsStatus(state, action) {
    state.emailsStatus = action.payload;
  },
  setSms(state, action) {
    state.sms = action.payload;
  },
  setSmsStatus(state, action) {
    state.smsStatus = action.payload;
  },
  setSystemCustomerTemplateList(state, action) {
    const { payload } = action;
    state.systemCustomerTemplate = payload;
  },
  setTemplateSnippitsList(state, action) {
    const { payload } = action;
    state.templateSnippitsList = payload;
  },
  setTemplatePreview(state, action) {
    const { payload } = action;
    state.templatePreview = payload;
  },
  setSmsTemplateList(state, action) {
    const { payload } = action;
    state.smsTemplateList = payload;
  },
  setClientSegmentList(state, action) {
    const { payload } = action;
    state.clientSegmentList = payload;
  },
  setClientSegmentListStatus(state, action) {
    const { payload } = action;
    state.clientSegmentListStatus = payload;
  },
  setNoteCategories(state, action) {
    const { payload } = action;
    state.noteCategories = payload;
  },
  setNoteTemplates(state, action) {
    const { payload } = action;
    state.noteTemplates = payload;
  },
  setFinanceGoals(state, action) {
    const { payload } = action;
    state.financeGoals = payload;
  },
};

const slice = createSlice({
  name: "clients",
  initialState,
  reducers,
});

export const {
  setAllClient,
  setFiltersList,
  setFilter,
  setFieldValues,
  setLetters,
  setClientNote,
  setClientNoteStatus,
  setClientActivity,
  setClientActivityStatus,
  setClientTask,
  setClientTaskStatus,
  setTaskSuggetion,
  setClientActionPlan,
  setClientActionPlanStatus,
  setClientReview,
  setClientReviewStatus,
  setClientDocument,
  setClientDocumentStatus,
  setClientAgreement,
  setClientAgreementStatus,
  setEmails,
  setSms,
  setAllClientStatus,
  setClientDetails,
  setClientStatus,
  setLettersStatus,
  setItems,
  setItemsStatus,
  setItem,
  setItemStatus,
  setItemDetails,
  setItemDetailsStatus,
  setEmailsStatus,
  setSmsStatus,
  setSystemCustomerTemplateList,
  setTemplateSnippitsList,
  setTemplatePreview,
  setSmsTemplateList,
  setClientSegmentList,
  setClientSegmentListStatus,
  setNoteCategories,
  setNoteTemplates,
  setFinanceGoals,
} = slice.actions;
export default slice.reducer;
