import { createSlice } from "@reduxjs/toolkit";
const initialStatus = {
    status: "LOADING",
    message: "",
};

const initialState = {
    activitiesList: {},
    activitiesListStatus: initialStatus,
    toastStatus: { message: "", color: "", status: "LOADING" },
};

const reducers = {
    setActivitiesList: (state, action) => {
        const { payload } = action;
        state.activitiesList = payload;
    },
    setActivitiesStatus: (state, action) => {
        const { payload } = action;
        state.activitiesListStatus = payload;
    },
    setToastStatus: (state, action) => {
        const { payload } = action;
        state.toastStatus = payload;
    },
};

const ActivitySlice = createSlice({
    name: "activity",
    initialState,
    reducers,
});

export const {
    setActivitiesList,
    setActivitiesStatus,
    setToastStatus,
} = ActivitySlice.actions;
export default ActivitySlice.reducer;
